<template>
  <div class="flex flex-col mb-10">
    <div v-if="packages.length > 0">
      <carousel :margin="10" :autoplay="true" :nav="false">
        <CourseCard
          v-for="item in packages"
          :key="item.id"
          :course="item"
          :selectedPackage="selectedPackage"
          @select-course="onSelectCourse"
        />
      </carousel>
    </div>
    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
        :loading="loading"
        class="bg-victoria rounded text-white mr-0"
        label="Save & Next"
        type="button"
        :disabled="!selectedPackage"
        @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import CourseCard from "@/components/molecule/registration/CourseCard";
import carousel from "vue-owl-carousel";
import { mapActions } from "vuex";
import { Button } from "@/components";

export default {
  components: {
    Button,
    CourseCard,
    carousel,
  },

  data() {
    return {
      loading: false,
      packages: [],
      selectedPackage: {},
    };
  },

  methods: {
    ...mapActions({
      // actPackagesList: "registration/actPackagesList",
      actPackages: "registration/actPackages",
    }),

    getFormDetails() {
      return this.getLocal("registration-student-info");
    },

    submit() {
      let formData = this.getLocal("registration-student-info");
      formData.packages = this.selectedPackage;
      this.setLocal("registration-student-info", formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: "registrationConfirmation" });
    },

    onSelectCourse(value) {
      this.selectedPackage = value;
    },

    clearAll() {
     let formData = this.getLocal('registration-student-info');
     this.selectedPackage = {};
     formData.packages = this.selectedPackage;
     this.setLocal('registration-student-info', formData);
    },
  },

  created() {
    let formDetails = this.getFormDetails();

    // console.log(formDetails);
    if (typeof formDetails !== "undefined" && formDetails) {
      let organizationId = formDetails.organization_id.slice(0, 1).shift();

      if (typeof organizationId !== "undefined" && organizationId) {
        // this.actPackagesList(organizationId).then(({ items }) => (this.packages = items));
        this.actPackages('?type=COURSE').then((items ) => (this.packages = items));
        this.selectedPackage = formDetails.packages;

      }
    }
  },
};
</script>
