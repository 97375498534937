<template>
  <div class="course-card" @click="selectCourse">
    <h2 class="bg-primary text-base text-white rounded p-2 text-center">{{ course.name || 'Name is empty' }}</h2>
    <div class="my-6 text-center">
      <span v-if="course.package_detail && course.package_detail.old_price > course.package_detail.new_price" class="discount-price">$ {{ course.package_detail.old_price }}</span>
      <h2 class="my-4 text-4xl font-bold">$ {{ course.package_detail && course.package_detail.new_price > 0 ?  course.package_detail.new_price :'' }}</h2>
    </div>

    <div class="featur-list">
      <ul>
        <li><span class="text-primary"> &check; </span> {{ (course.course_duration) / 30 }} months duration</li>
        <li><span class="text-primary"> &check; </span> {{ course.is_pte_practice }} PTE practice</li>
        <li><span class="text-primary"> &check; </span> {{ course.is_practice_test }} Practice test</li>
        <li><span class="text-primary"> &check; </span> {{ course.is_live_class }} Live class</li>
        <li><span class="text-primary"> &check; </span>{{ course.one_to_one_appointment }} One to one appointment</li>
      </ul>
    </div>
    <div class="my-6 text-center">
      <p class="text-uppercase inline-block font-semibold text-underline text-primary pointer">View Details</p>
    </div>

    <svg v-if="isActive" class="w-10 checked-icon" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48">
      <path fill="#00df05" d="M44,24c0,11-9,20-20,20S4,35,4,24S13,4,24,4S44,13,44,24z"/>
      <polyline fill="none" stroke="#fff" stroke-width="4" points="14,24 21,31 36,16"/>
    </svg>

    <div v-if="isPopular" class="branding rounded text-white text-uppercase font-bold text-center p-1">
      Popular
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /*isActive: {
      type: Boolean,
      default: () => false
    },*/
    course: {
      type: Object
    },
    selectedPackage: {
      type: Object
    },
    isPopular: {
      type: Boolean,
      default: () => false
    },
  },
  computed: {
    isActive() {
      
      // console.log(this.course);
      if (typeof this.selectedPackage !== "undefined" && typeof this.course !== "undefined") {
        if (this.selectedPackage.id == this.course.id) {
          return true;
        }
      }
      return false;
    },
    detail() {
      return this.course.package_detail;
    }
  },
  methods: {
    selectCourse() {
      this.$emit('select-course', this.course)
    },
  }
}
</script>