<template>
  <div class="flex flex-col mb-10">

    <AddressComponent 
      :isAddressOneShow="false" 
      :isAddressTwoShow="false" 
      :data="form"  
      ref="address" 
    />

    <div class="flex justify-end mt-4">
      <md-button class="ml-auto text-uppercase" @click="clearAll">Clear all</md-button>
      <Button
          :loading="loading"
          class="bg-victoria rounded text-white mr-0"
          label="Save & Next"
          type="button"
          @click="submit"
      >
      </Button>
    </div>
  </div>
</template>

<script>
import {
  Button,
  AddressComponent
} from "@/components";

export default {
  components: {
    Button,
    AddressComponent
  },

  data() {
    return {
      loading: false,
      radio: false,
      form: {
        country: '',
        country_id: '',
        your_country: '',
        your_country_id: '',
        postal_code_id: '',
        postal_code: '',
        state: '',
        city: ''
      }
    }
  },

  methods: {

    submit() {

      this.form = this.$refs.address.$data.form;
      if(this.$refs.address.checkValidation()) return;

      let formData = this.getLocal('registration-student-info');
      formData.user_profile = {
        ...formData.user_profile,
        ...this.form
      };
      this.setLocal('registration-student-info', formData);
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'registrationCourse' });
    },

    clearAll() {

      this.form = {
        country: '',
        country_id: '',
        your_country: '',
        your_country_id: '',
        postal_code_id: '',
        postal_code: '',
        state: '',
        city: ''
      }
      let formData = this.getLocal('registration-student-info');
      formData.user_profile.country = this.form.country;
      formData.user_profile.country_id = this.form.country_id;
      formData.user_profile.your_country = this.form.your_country;
      formData.user_profile.your_country_id = this.form.your_country_id;
      formData.user_profile.postal_code_id = this.form.postal_code_id;
      formData.user_profile.postal_code = this.form.postal_code;
      formData.user_profile.state = this.form.state;
      formData.user_profile.city = this.form.city;

      this.$refs.address.clearAll()

      this.setLocal('registration-student-info', formData);
    }
  },

  created() {
  
    let existData = this.getLocal('registration-student-info');
    if (existData && existData.user_profile.postal_code_id) this.form = existData.user_profile;
  

  }
}
</script>